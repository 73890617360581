import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.2_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.2_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.2_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/contac/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/active-link/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/button/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/experiences/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/info/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/projects/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/tags/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/carousel/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/native-link/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/success-dialog/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/text-input/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/link/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tag/styles.module.css");
